/* You can add global styles to this file, and also import other style files */
 
// .k-multiselect .k-multiselect-wrap .k-searchbar .k-input, .k-dropdowntree .k-multiselect-wrap .k-searchbar .k-input, 
// .k-animation-container li, .k-animation-container-shown li,
// .k-popup .k-item,
// .k-popup .k-calendar,
// .custom-kendo-input,
// .k-multiselect-wrap
// {
//    font-size: 12px !important;
//    color: #000 !important;
//    font-family: Arial !important;
// }

// .k-label {
//     font-size: 13px !important;
//     color: #000 !important;
//     font-family: Arial !important;
// }

// .kendo-input-style {
//     height: 80% !important;
//     width: 100% !important;
//     border : 1px solid #000 !important;
// }

// .k-textarea {
//     width: 100% !important;
//     border : 1px solid #000 !important;
// }

// .k-checkbox {
//     border : 1px solid #000 !important;  
// }

// .bg-style.k-numerictextbox .k-numeric-wrap {
//     background: gainsboro;
// }
// .bg-style.k-textbox  {
//     background: gainsboro;
// }

body {
    margin: 0 !important;
    font-family: Arial;
}

.main {
    min-height: 100vh;
    // background-color: $lm-color-background-atmospheric;
}

// TODO: Remove. Only used for starting readme
p > a {
    text-decoration: underline !important;
    color: #037B86 !important;
}

.container{
    max-width: 99%;
}

.container-fluid{
    margin-bottom: -22px;
}

.font-style {
    font-size: 13px;
}

.font-heading-style {
    font-size: 15px;
}

.error-style {
    color: red;
}

.d-align-center {
    display: flex;
    align-items: center;
}

body .ui-widget {
    font-size: 8pt!important;
}

body .ui-menubar {
    padding: 0;
    background-color: #fff;
    border: 1px solid #c8c8c8;
}

::ng-deep .p-component {
    font-family: Arial;
    font-size: 8pt !important;
    font-weight: normal;
}